import { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import useLocalStorage from "utils/useLocalStorage";
import PlanPage from "pages/planPage";
import StartPage from "pages/startPage";
import Confirmation from "pages/confirmation";
import MoneyBackPolicyPage from "pages/moneyBackPolicy";

function App() {
  const { setValue, storedValue } = useLocalStorage("plannerQuestions");

  const [formData, setFormData] = useState(storedValue);
  const successForm = formData["email-approval"];
  const userGender = formData["user-gender"];

  const handleDataChange = useCallback(
    (data) => {
      setValue(data);
      setFormData(data);
    },
    [setValue],
  );

  useEffect(() => {
    const container = document.getElementById("root");

    if (userGender && container && !container.classList.length) {
      container.classList.add(userGender);
    }
  }, [userGender]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            successForm ? (
              <PlanPage formData={formData} />
            ) : (
              <StartPage formData={formData} setFormData={handleDataChange} />
            )
          }
        />
        <Route path="/allset" element={<Confirmation />} />
        <Route path="/money-back-policy" element={<MoneyBackPolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;

function useLocalStorage(key) {
  const storedValue = () => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      console.error("Error reading localStorage key", key, error);
      return {};
    }
  };

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue()) : value;
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error("Error setting localStorage key", key, error);
    }
  };

  return { storedValue: storedValue(), setValue };
}

export default useLocalStorage;

import { memo, useRef, useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";

import useLocalStorage from "utils/useLocalStorage";
import FormHeader from "sections/formHeader";
import Button from "components/button";
import PolicyBlock from "components/policyBlock";
import SingleOption from "components/singleOption";
import DynamicComponent from "components/dynamicImport";

import "swiper/css";
import "swiper/css/pagination";

import "./style.scss";
import useFetchQuestions from "utils/useFetchQuestions";

const Questions = ({ gender, formData, setFormData }) => {
  const { questions } = useFetchQuestions();

  const swiperRef = useRef(null);

  const {
    setValue,
    storedValue: {
      storedSubStep,
      storedDoneSteps,
      storedTotalStep,
      storedParentStep,
      storedPolicySelected,
    },
  } = useLocalStorage("stepInfo");

  const [parentStep, setParentStep] = useState(storedParentStep || 0);
  const [subStep, setSubStep] = useState(storedSubStep || 0);
  const [totalStep, setTotalStep] = useState(storedTotalStep || 0);
  const [doneSteps, setDoneSteps] = useState(storedDoneSteps || []);
  const [policySelected, setPolicySelected] = useState(
    storedPolicySelected || false,
  );
  const [showPolicyWarning, setShowPolicyWarning] = useState(0);

  useEffect(() => {
    setValue({
      storedSubStep: subStep,
      storedDoneSteps: doneSteps,
      storedTotalStep: totalStep,
      storedParentStep: parentStep,
      storedPolicySelected: policySelected,
    });
  }, [policySelected, setValue, totalStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToNextSlide = useCallback(() => {
    const currentSubArray = questions[parentStep];

    if (subStep === 0) {
      setDoneSteps(parentStep);
    }

    if (subStep < currentSubArray?.length - 1) {
      setSubStep((prev) => prev + 1);
    } else if (parentStep < questions.length) {
      setParentStep((prev) => prev + 1);
      setSubStep(0);
    }

    setTotalStep((prev) => prev + 1);
    swiperRef.current?.slideNext();
  }, [parentStep, questions, subStep]);

  const goToPrevSlide = useCallback(() => {
    if (subStep > 0) {
      setSubStep((prev) => prev - 1);
    } else if (parentStep > 0) {
      setParentStep((prev) => prev - 1);
      setSubStep(questions[parentStep - 1]?.length - 1);
      setDoneSteps(parentStep - 1);
    }

    setTotalStep((prev) => prev - 1);
    swiperRef.current?.slidePrev();
  }, [parentStep, questions, subStep]);

  const handlePolicy = useCallback(() => {
    setPolicySelected((prev) => {
      setShowPolicyWarning(prev ? Date.now : 0);

      return !prev;
    });

    if (!policySelected && formData["goal-1"]) {
      setTimeout(goToNextSlide, 400);
      setShowPolicyWarning(0);
    }
  }, [policySelected, formData, goToNextSlide]);

  const handleSelected = useCallback(
    ({ key, value, checkbox, stayHere, props }) => {
      setFormData((prev) => {
        if (checkbox) {
          if (typeof prev[key] === "object" && prev[key]?.includes(value)) {
            return {
              ...prev,
              [key]: prev[key].filter((item) => item !== value),
            };
          } else {
            const pVal =
              typeof prev[key] !== "object" && checkbox ? [] : prev[key];

            return { ...prev, [key]: [...(pVal || []), value] };
          }
        } else {
          return { ...prev, [key]: value };
        }
      });

      if (!stayHere && !checkbox && policySelected && !props) {
        setTimeout(goToNextSlide, 600);
      }

      if (!policySelected) {
        setShowPolicyWarning(Date.now);
      }
    },
    [policySelected, goToNextSlide, setFormData],
  );

  const handleOptionClick = useCallback(
    (key, value, checkbox, stayHere) =>
      handleSelected({ key, value, checkbox, stayHere }),
    [handleSelected],
  );

  return (
    <div className="formWrapper" id="formWrapper">
      <FormHeader
        almostDone={!!formData["email"]}
        parentStep={parentStep}
        subStep={subStep}
        totalStep={totalStep}
        doneSteps={doneSteps}
        onPrev={goToPrevSlide}
      />
      <Swiper
        speed={400}
        longSwipes
        keyboard
        className="formSwiper"
        initialSlide={totalStep}
        allowTouchMove={false}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {questions.map((steps, parentIndex) =>
          steps.map(
            (
              {
                key,
                title,
                component,
                buttonText,
                checkbox,
                content,
                props,
                column,
                showButton = false,
                options = [],
              },
              subIndex,
            ) => {
              const totalIndex =
                questions
                  .slice(0, parentIndex)
                  .reduce((sum, step) => sum + step.length, 0) + subIndex;

              return (
                <SwiperSlide key={`${parentIndex}-${subIndex}`}>
                  <div className={clsx("questionContent", key)}>
                    <>
                      <p className="title">{title}</p>
                      {typeof content === "string" && (
                        <p className="description">{content}</p>
                      )}
                      {!!options?.length && (
                        <div className={clsx("optionBlock", { column })}>
                          <>
                            {options.map(
                              (
                                { label, image, value, description },
                                optionIndex,
                              ) => (
                                <SingleOption
                                  name={key}
                                  value={value}
                                  label={label}
                                  key={optionIndex}
                                  image={image?.[gender]}
                                  description={description}
                                  stayHere={checkbox}
                                  checkbox={value !== "other" && checkbox}
                                  isSelected={formData?.[key]?.includes(value)}
                                  onClick={handleOptionClick}
                                >
                                  {label}
                                </SingleOption>
                              ),
                            )}
                          </>
                        </div>
                      )}
                      {component && totalStep >= totalIndex && (
                        <DynamicComponent
                          name={key}
                          props={props}
                          formData={formData}
                          value={formData?.[key]}
                          componentName={component}
                          onChange={handleSelected}
                        />
                      )}
                      {parentIndex + subIndex === 0 && (
                        <PolicyBlock
                          onClick={handlePolicy}
                          selected={policySelected}
                          showWarning={showPolicyWarning}
                        />
                      )}
                    </>
                    {((checkbox && formData?.[key]?.length > 0) ||
                      (!options.length &&
                        !props &&
                        formData?.[key]?.length > 0) ||
                      showButton ||
                      (props && !!formData?.[key])) && (
                      <div className="actionBlock">
                        <Button onClick={goToNextSlide}>
                          {buttonText || "Continue"}
                        </Button>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            },
          ),
        )}
      </Swiper>
    </div>
  );
};

export default memo(Questions);

import { memo, useState } from "react";

import Input from "components/input";

import "./style.scss";
import Button from "components/button";

const ChangePasswordBlock = ({ emailValueFromStorage, setIsChanged }) => {
  const [inpPassword, setInpPassword] = useState("");
  const [inpPassword2, setInpPassword2] = useState("");

  const usePostFetch = (url) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const postData = async (params) => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        });

        if (!response.ok) {
          console.error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setData(result);

        if (!result.error && result.data === "Done") {
          setIsChanged(true);
          localStorage.removeItem("stepInfo");
          localStorage.removeItem("plannerQuestions");
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    return { data, loading, postData };
  };

  const { data, loading, postData } = usePostFetch(
    "https://api.gymdone.com/v13/set-password",
  );

  const { error, msg: errorMsg } = data || {};

  const handleSignUp = () => {
    const params = {
      email: emailValueFromStorage,
      password: inpPassword,
      confirmPassword: inpPassword2,
    };

    postData(params);
  };

  return (
    <>
      <div className="optionBlock">
        <div className="inputBlockWrapper">
          <p className="inputLabel">Account email</p>
          <Input
            isSmall
            type="text"
            value={emailValueFromStorage}
            readOnly
            preventRemove
          />
          <p className="inputLabel">Password</p>
          <Input
            isSmall
            type="text"
            value={inpPassword}
            readOnly={loading}
            preventRemove
            regexp="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$"
            errorMessage="Password must be at least 6 characters long and include at least one letter and one number."
            onChange={(val) => setInpPassword(val)}
          />
          <p className="inputLabel">Confirm password</p>
          <Input
            isSmall
            type="text"
            value={inpPassword2}
            readOnly={loading}
            preventRemove
            passSameValue={inpPassword}
            errorMessage="Passwords do not match"
            onChange={(val) => setInpPassword2(val)}
          />
          {error && (
            <div className="hint error">
              <p className="title">
                <i className="icon-square-exclamation" /> Something went wrong
              </p>
              <p className="content">{errorMsg}</p>
            </div>
          )}
        </div>
      </div>
      {!!inpPassword &&
        !!inpPassword2 &&
        inpPassword === inpPassword2 &&
        !loading && (
          <div className="actionBlock">
            <Button onClick={handleSignUp}>Continue</Button>
          </div>
        )}
    </>
  );
};

export default memo(ChangePasswordBlock);

import { useNavigate } from "react-router-dom";
import "./style.scss";

const MoneyBackPolicyPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="moneyBackPolicyPage">
      <div className="backButton" onClick={handleBack}>
        <i className="icon-arrow-left" />
        Back to Plan
      </div>
      <div className="content">
        <p className="lastUpdated">Last updated on Dec 19, 2024</p>
        
        <h1>MONEY-BACK POLICY</h1>
        
        <section>
          <h2>I. MONEY-BACK POLICY</h2>
          <p>
            In addition to refund rights available under applicable laws, if you made a purchase directly on our website and the money-back option was presented to you during the checkout and if you did not achieve visible results with our personal plan, you are eligible to receive a refund provided that all of the following conditions are met:
          </p>
          <ul>
            <li>you contacted us within 30 days after the purchase and</li>
            <li>you have followed the program for at least 14 consecutive days within the first 30 days after the purchase.</li>
          </ul>
          <p>
            We will review your application and notify you by email whether your application is approved. If the application is approved, your refund will be processed, and a credit will be automatically applied to your credit card or original method of payment, within 14 days.
          </p>
          <p>
            Customers who subscribed to our program through an App Provider will need to reach out to Apple directly with any refund requests. Apple is typically able to honor all refund requests within 30 days of the purchase date (at their discretion).
          </p>
        </section>

        <section>
          <h2>IMPORTANT STATEMENT</h2>
          <p>
            Please note that only fulfilment of all the above requirements allows you to receive a full Voluntary Refund under "Money-back guarantee". For the sake of clarity, this "Money-back guarantee" does not apply to the following cases:
          </p>
          <ul>
            <li>Personal reasons (you don't like the product, it did not meet your expectations etc.);</li>
            <li>Financial reasons (you did not expect that you will be charged, that the trial will be converted into subscription, that the subscription will automatically renew, or that the services are paid etc.).</li>
          </ul>
          <p className="notice">
            This Money-back Policy was posted on 8 May 2022. It is effective upon acceptance for new users and from 13 December 2021 for all other users. The Money-Back Policy is incorporated into and is subject to the Terms of Service unless otherwise provided for therein.
          </p>
        </section>

        <section>
          <h2>CANCELLATION POLICY</h2>
          <p>
            We value our customers and you are welcome to cancel your subscription any time by request. Please contact us at <a href="mailto:support@workout-plan.app">support@workout-plan.app</a> or within the app.
          </p>
          <p>
            Please, be aware that deleting the app or your account does not imply that the subscription is canceled.
          </p>
        </section>

        <section>
          <h2>DELIVERY POLICY</h2>
          <p>
            After the successful purchase, you will get access to your personal plan immediately tailored for you in our iOS app. Additionally, you will get an email with the link to the plan. You will just need to download the app and start.
          </p>
          <p>
            If after the payment you somehow did not receive the download link, please contact us at <a href="mailto:support@workout-plan.app">support@workout-plan.app</a>.
          </p>
        </section>

        <section>
          <h2>II. GENERAL REFUND RULES</h2>
          <p>
            Generally, if you do not meet the conditions set out above, the fees you have paid are non-refundable and/or non-exchangeable, unless otherwise is stated herein or is required by applicable law.
          </p>
          <div className="stateNotices">
            <p>
              <strong>Note for residents of certain US states:</strong> If you reside in California or Connecticut and cancel the purchase at any time prior to midnight of the third business day after the date of such purchase, we will return the payment you have made.
            </p>
            <p>
              <strong>Note for the EU residents:</strong> If you are an EU resident, you have the right to withdraw from the agreement for purchase of digital content without charge and without giving any reason within fourteen (14) days from the date of such agreement conclusion. The withdrawal right does not apply if the performance of the agreement has begun with your prior express consent and your acknowledgment that you thereby lose your right of withdrawal. You hereby expressly consent to the immediate performance of the agreement and acknowledge that you will lose your right of withdrawal from the agreement once our servers validate your purchase and the applicable purchase is successfully delivered to you. Therefore, you will not be eligible for a refund, unless the digital content is defective.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MoneyBackPolicyPage; 
import { Suspense, lazy, memo, useMemo } from "react";

const DynamicComponent = ({
  componentName,
  onChange,
  formData,
  name,
  value,
  props,
}) => {
  const LazyComponent = useMemo(
    () =>
      lazy(() =>
        import(`../questionComponents/${componentName}`).catch(() => {
          return { default: () => <div>Component not found</div> };
        }),
      ),
    [componentName],
  );

  return (
    <Suspense fallback={<div className="optionBlock">Loading...</div>}>
      <LazyComponent
        {...props}
        value={value}
        name={name}
        formData={formData}
        onChange={onChange}
      />
    </Suspense>
  );
};

export default memo(DynamicComponent);

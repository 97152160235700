import { memo, useEffect, useRef, useState } from "react";
import "./style.scss";
import TextTransition, { presets } from "react-text-transition";

function processNumber(num) {
  const strNum = num.toString();
  return num > 10 ? parseInt(strNum.slice(0, -1)) : 1;
}

const InfoProgress = ({ titles, loading, setLoading, duration = 2000 }) => {
  const textRef = useRef();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!loading) {
      return;
    }

    const randomDuration =
      Math.floor(Math.random() * (duration + 2000 - duration + 1)) + duration;

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 110) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, randomDuration / 100);

    return () => clearInterval(interval);
  }, [loading, duration]); // Only run when `loading` or `duration` changes

  useEffect(() => {
    if (progress >= 100 && loading) {
      setLoading(false); // Ensure this only runs once
    }
  }, [progress, loading, setLoading]); // Dependencies explicitly listed

  return (
    <div className="compProgressWrapper">
      {titles && (
        <TextTransition inline className="title" springConfig={presets.default}>
          {titles[processNumber(progress)]}
        </TextTransition>
      )}
      <div className="progressBlock">
        <p ref={textRef}>{progress < 100 ? progress : 100}%</p>
        <div className="line" style={{ width: `${progress + 3}%` }}>
          <p style={{ width: textRef.current?.offsetWidth }}>
            {progress < 100 ? progress : 100}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(InfoProgress);

import React, { useState } from "react";
import Lottie from "react-lottie";

import useLocalStorage from "utils/useLocalStorage";
import ChangePasswordBlock from "components/questionComponents/changePasswordBlock";

import "pages/planPage/style.scss";
import successLottie from "assets/success.json";
import downloadAppQr from "assets/downloadApp.png";
import downloadAppIos from "assets/iosD.png";
import downloadAppAndroid from "assets/androidD.png";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const PaymentConfirmation = () => {
  const [isChanged, setIsChanged] = useState(false);

  const { storedValue: { email: emailValueFromStorage = "" } = {} } =
    useLocalStorage("plannerQuestions");

  if (isChanged || !emailValueFromStorage) {
    return (
      <div className="planPage">
        <div className="downloadInfo">
          <h1>
            Scan QR code with
            <br /> your phone to get app.
          </h1>
          <p>Choose the option that fit you best, scan QR or visit stores.</p>
          <img src={downloadAppQr} alt="download qr" />
          <a
            className="appDownload"
            rel="noopener noreferrer"
            href="https://itunes.apple.com/app/gym-done-pure-workout-dumbell/id1350534443"
            target="_blank"
          >
            <img src={downloadAppIos} alt="ios app" />
          </a>
          <a
            className="appDownload"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.gymdone.gymworkouttrainer"
            target="_blank"
          >
            <img src={downloadAppAndroid} alt="android app" />
          </a>
          <div className="orPart">
            <h2>Log in manually</h2>
            <p>
              Tap 'Continue with account' on the first screen of the  workout-planner.app(by GYMDONE) and use
              the
              {emailValueFromStorage} email and password you set during the
              previous step.
            </p>
            <img src="/images/allsetImage.jpg" alt="all set image" />
            <p>
              If you encounter any difficulties logging into the app,{" "}
              <b>please refrain from making further purchases within the app</b>
              .Instead, please contact us directly at{" "}
              <a href="mailto:support@workout-planner.app">
                support@workout-planner.app
              </a>{" "}
              and our support team will assist you as quickly as possible.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="planPage">
      <div className="successPayment">
        <Lottie options={defaultOptions} height={180} width={180} />
        <h1>Payment Approved!</h1>
        <p>
          To complete your registration, please set a password for your account
        </p>
      </div>
      <ChangePasswordBlock
        setIsChanged={setIsChanged}
        emailValueFromStorage={emailValueFromStorage}
      />
    </div>
  );
};

export default PaymentConfirmation;

import { useState, useEffect } from "react";

const useFetchQuestions = () => {
  const [{ questions = [], stepTitle = [], planInfo = [] }, setQuestions] =
    useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedData = sessionStorage.getItem("questions");
    if (storedData) {
      setQuestions(JSON.parse(storedData));
      setLoading(false);
    } else {
      fetch("/questions.json")
        .then((response) => response.json())
        .then((data) => {
          setQuestions(data);
          sessionStorage.setItem("questions", JSON.stringify(data));
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  return { questions, planInfo, stepTitle, loading, error };
};

export default useFetchQuestions;

import { useState } from "react";
import clsx from "clsx";

import Timer from "components/timer";
import Check from "components/check";
import Button from "components/button";

import "./style.scss";
import useLocalStorage from "utils/useLocalStorage";
import useFetchQuestions from "utils/useFetchQuestions";
import {
  exLevels,
  fatValues,
  fitnessLevel,
  massStepMapping,
} from "constants/bodyFat";

const countdownTime = Date.now() + 10 * 60 * 1000;

const PlanPage = ({ formData }) => {
  const {
    planInfo: {
      prevDiscount,
      discount,
      prevPromo,
      promo,
      available = [],
      selectedPlanId,
    } = {},
  } = useFetchQuestions();

  const { setValue: setSteps } = useLocalStorage("stepInfo");
  const { setValue } = useLocalStorage("stripeStatus");
  const { setValue: setQuestions } = useLocalStorage("plannerQuestions");

  const [selectedPlan, setSelectedPlan] = useState();
  const userEmail = formData["email"];
  const gender = formData["user-gender"];
  const globalGoal = formData["goal-1"];
  const {
    id: fatId,
    name: bodyFatType,
    image: { [gender]: bodyFatImage },
  } = fatValues.find(
    ({ id }) => id === String(formData["body-fat-level"]) || 1,
  );
  const selectedPlanInfo =
    available.find(({ id }) => id === (selectedPlan || selectedPlanId)) || {};

  const [startMassStep, lastMassStep] =
    massStepMapping[fatId || 1][globalGoal || 1];

  const handlePurchase = () => {
    setValue("unpaid");
    window.location.href = `https://buy.stripe.com/${selectedPlanInfo.stripeId}?prefilled_email=${userEmail}`; // Replace with your Stripe Buy Link URL
  };

  const handleReset = () => {
    setValue(null);
    setSteps({});
    setQuestions({});

    window.location.reload();
  };
  //
  // if (storedValue === "unpaid") {
  //   return (
  //     <div className="planPage center">
  //       <div className="paymentInfo">
  //         <i className="icon-square-exclamation" /> You have an unpaid payment
  //         <Button isPrimary onClick={handlePurchase}>
  //           Continue
  //         </Button>
  //         OR <Button onClick={handleReset}>Reset all information</Button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="planPage">
      <div className="beforeAfterBlock">
        <div className="header">
          <p>{bodyFatType} body type</p>
        </div>
        <div className="imageBlock">
          <img src={bodyFatImage} alt="now" className="body" />
        </div>
        <div className="info">
          <div className="sub">
            <p className="title">Current weight</p>
            <p className="detail">
              {formData["current-weight"]?.value}{" "}
              {formData["current-weight"]?.tab}
            </p>
          </div>
          <div className="sub">
            <p className="title">Target weight</p>
            <p className="detail">
              {" "}
              {formData["target-weight"]?.value}{" "}
              {formData["target-weight"]?.tab}
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub">
            <hr />
          </div>
          <div className="sub">
            <hr />
          </div>
        </div>
        <div className="info">
          <div className="sub">
            <p className="title">Start progress</p>
            <div className="massDetail">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={clsx("line", {
                    active: index + 1 <= startMassStep,
                  })}
                />
              ))}
            </div>
          </div>
          <div className="sub">
            <p className="title">End progress</p>
            <div className="massDetail">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={clsx("line", {
                    active: index + 1 <= lastMassStep,
                  })}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="guaranteeBlock">
        <i className="icon-square-exclamation" /> This is not a guarantee or
        promise of results.
      </div>
      <div className="reservedDiscount">
        <div className="timerBlock">
          <p className="title">Discount reserved for:</p>
          <Timer deadline={countdownTime} />
        </div>
        <Button isPrimary isSmall onClick={handlePurchase}>
          Get My Plan
        </Button>
      </div>
      <div className="aiBlock">
        <h2>
          Your <b>AI-Optimized</b> Plan
        </h2>
        <div className="content">
          <div className="aiBadge">
            <i className="icon-stars" />
            WORKOUT PROGRAMM
          </div>
          <div className="single">
            <i className="icon-goal" />
            <div className="info">
              <p className="title">Your Goal</p>
              <p className="value">{exLevels[globalGoal]}</p>
            </div>
          </div>
          <div className="single">
            <i className="icon-duration" />
            <div className="info">
              <p className="title">Duration</p>
              <p className="value">12 weeks</p>
            </div>
          </div>
          <div className="single">
            <i className="icon-wight" />
            <div className="info">
              <p className="title">Target weight</p>
              <p className="value">
                {formData["target-weight"]?.value}{" "}
                {formData["target-weight"]?.tab}
              </p>
            </div>
          </div>
          <div className="single">
            <i className="icon-level3" />
            <div className="info">
              <p className="title">Your Level</p>
              <p className="value">{fitnessLevel[formData["fitness-level"]]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="promoBlock">
        <h2>Reach your goals with your personalized workout plan!</h2>
        <div className="content">
          <div className="leftSide">
            <p>Applied limited discount</p>
            <div className="promo">
              <div className="percentBadge">
                <b>{prevDiscount}%</b> {discount}% off
              </div>
              <p className="line">{prevPromo}</p>
              <p className="newPromo">{promo}</p>
            </div>
          </div>
          <div className="rightSide">
            <p>Applicable for</p>
            <div className="timerBlock">
              <Timer deadline={countdownTime} withName />
            </div>
          </div>
        </div>
      </div>
      <div className="planOptions">
        {available.map(
          ({ id, title, isPopular, price, pricePrev, priceDay }) => (
            <div
              key={id}
              onClick={() => setSelectedPlan(id)}
              className={clsx("singlePlan", {
                selected: selectedPlanInfo.id === id,
              })}
            >
              <Check circle selected={selectedPlanInfo.id === id} />
              <div className="info">
                {isPopular && (
                  <div className="popular">
                    <i className="icon-hot" />
                    Most Popular
                  </div>
                )}
                <p className="title">{title}</p>
                <p className="priceDay">
                  <span className="prevPrice">${pricePrev}</span> ${price}
                </p>
              </div>
              <div className="priceBlock">
                <p className="price">${priceDay}</p>
                <p className="range">per day</p>
              </div>
            </div>
          ),
        )}
        <p className="moneyBackInfo">30-day money-back guarantee</p>
        <Button isPrimary onClick={handlePurchase}>
          Get My Plan
        </Button>
        <ul className="subscriptionInfo">
          <li>This is an automatically renewing subscription.</li>
          <li>
            Today, workout-planner.app will charge you $
            {selectedPlanInfo.totalPrice} as an sale offer.
          </li>

          <li>
            Your payment method will be automatically charged every selected
            period until you will cancel the subscription.
          </li>
          <li>
            You can cancel the subscription at any time through the
            workout-planner.app settings or by contacting support at{" "}
            <a href="mailto:support@workout-planner.app">
              support@workout-planner.app
            </a>
            .
          </li>
          <li>
            This subscription is subject to workout-planner.app' by GYMDONE{" "}
            <a
              href="https://www.gymdone.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.gymdone.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            .
          </li>
        </ul>
      </div>
      <div className="moneyBackGuarantee">
        <div className="stampContainer">
          <img src="/images/30-days-guarantee.png" alt="30 Days Money Back Guarantee" className="stamp" />
        </div>
        <div className="content">
          <h2>100% Money Back Guarantee</h2>
          <p className="description">
            We have confidence in the quality of our service and the results it provides. If you do not achieve visible results within the first 30 days, you are eligible for a refund. Please note that you will need to demonstrate that you followed the program.
          </p>
          <p className="policy">
            For more information on the terms and conditions of our money-back policy, please see{" "}
            <a href="/money-back-policy" target="_blank" rel="noreferrer">
              our money-back policy
            </a>
            .
          </p>
          <p className="cta">
            Let us help you reach your health goals today!
          </p>
        </div>
      </div>

      <div className="appStoreRatings">
        <div className="storeButtons">
          <div className="storeButton">
            <svg className="storeIcon" viewBox="0 0 24 24" fill="currentColor">
              <path d="M17.05 20.28c-.98.95-2.05.8-3.08.35-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.79 1.18-.19 2.31-.89 3.51-.84 1.54.07 2.7.61 3.47 1.72-3.09 1.83-2.37 5.87.79 7.14-.65 1.87-1.51 3.71-2.85 4.16ZM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25.32 2.32-1.89 4.27-3.74 4.25Z"/>
            </svg>
            <span>App Store</span>
          </div>
          <div className="storeButton">
            <svg className="storeIcon" viewBox="0 0 24 24" fill="currentColor">
              <path d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z"/>
            </svg>
            <span>Google Play</span>
          </div>
        </div>
        <div className="ratings">
          <div className="stars">
            {'★'.repeat(5)} <span className="rating">4.7</span><span className="outOf">out of 5</span>
          </div>
        </div>
        <div className="stats">
          <span className="downloads">55 MILLION Workouts Done</span>
          <span className="separator">|</span>
          <span className="reviews">39K 5-star ratings</span>
        </div>
      </div>
    </div>
  );
};

export default PlanPage;

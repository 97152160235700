import { useCallback, useState } from "react";

import Start from "pages/start";
import Questions from "pages/questons";

function StartPage({ formData, setFormData }) {
  const [gender, setGender] = useState(formData["user-gender"]);

  const handleSetGender = useCallback(
    (val) => {
      setGender(val);
      setFormData({ "user-gender": val });
    },
    [setFormData],
  );

  return gender ? (
    <Questions gender={gender} formData={formData} setFormData={setFormData} />
  ) : (
    <Start setGender={handleSetGender} />
  );
}

export default StartPage;

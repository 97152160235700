import { memo, useState } from "react";

import "./style.scss";
import clsx from "clsx";
import InfoProgress from "components/infoProgress";

const Start = ({ setGender }) => {
  const [button, setButton] = useState("");
  const [loading, setLoading] = useState(true);

  const handleGender = (gender) => {
    setButton(gender);

    setTimeout(() => setGender(gender), 700);
  };

  return (
    <div className="startWrapper">
      <div className="headerImageWrapper">
        <img src="/images/start/header.jpeg" alt="header" />
      </div>
      <div className="infoContent">
        <div className="logoBlock">
          <i className="icon-icon" />{" "}
          <img src="/images/start/colors.png" alt="colors" />
        </div>
        <h1>
          Get Personal Workout Plan
        </h1>
        <h2>
          Get a new you in (based on your answers*) weeks.
          <br />
          Take the FREE body type quiz and discover a plan customized just for you!
        </h2>
        {loading ? (
          <InfoProgress setLoading={setLoading} loading={loading} />
        ) : (
          <div className="genderBlock">
            <div
              onClick={() => handleGender("female")}
              className={clsx("genderButton female", {
                active: button === "female",
              })}
            >
              <i className="icon-girl" />
              Female
            </div>
            <div
              onClick={() => handleGender("male")}
              className={clsx("genderButton male", {
                active: button === "male",
              })}
            >
              <i className="icon-boy" />
              Male
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Start);

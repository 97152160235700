export const fatValues = [
  {
    name: "Slim",
    id: 1,
    image: {
      male: "/images/fatLevel/m0.jpeg",
      female: "/images/fatLevel/w0.jpeg",
    },
  },
  {
    name: "Average",
    id: 2,
    image: {
      male: "/images/fatLevel/m1.jpeg",
      female: "/images/fatLevel/w1.jpeg",
    },
  },
  {
    name: "Stocky",
    id: 3,
    image: {
      male: "/images/fatLevel/m2.jpeg",
      female: "/images/fatLevel/w2.jpeg",
    },
  },
  {
    name: "Heavyset",
    id: 4,
    image: {
      male: "/images/fatLevel/m3.jpeg",
      female: "/images/fatLevel/w3.jpeg",
    },
  },
];

export const massStepMapping = {
  1: {
    1: [1, 5],
    2: [1, 3],
    3: [1, 4],
  },
  2: {
    1: [2, 5],
    2: [2, 4],
    3: [2, 5],
  },
  3: {
    1: [3, 5],
    2: [3, 2],
    3: [3, 4],
  },
  4: {
    1: [4, 5],
    2: [4, 1],
    3: [4, 3],
  },
};

export const exLevels = {
  1: "Muscle Gain",
  2: "Weight Loss",
  3: "Improve fitness",
};

export const fitnessLevel = { 1: "Beginner", 2: "Intermediate", 3: "Advanced" };

import { memo } from "react";
import clsx from "clsx";

import Check from "components/check";

import "./style.scss";

const SingleOption = ({
  onClick,
  image,
  name,
  value,
  checkbox,
  description,
  label,
  className,
  isSelected,
  stayHere,
}) => {
  return (
    <div
      onClick={() => onClick(name, value, checkbox, stayHere)}
      className={clsx("singleOption", className, {
        withImage: image,
        selected: isSelected,
      })}
    >
      {checkbox && !image && <Check selected={isSelected} />}
      <p className="label">{label}</p>
      {description && <p className="description">{description}</p>}
      {image && (
        <div className="imageBlock">
          <img src={image} alt={label} />
        </div>
      )}
    </div>
  );
};

export default memo(SingleOption);

import { memo, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import "./style.scss";

const Input = ({
  name,
  value,
  onChange,
  placeholder,
  regexp,
  type,
  readOnly,
  errorMessage,
  isSmall,
  passSameValue,
  preventRemove,
}) => {
  const [error, setError] = useState(false);
  const [localValue, setLocalValue] = useState(value || "");
  const inputRef = useRef(null);

  const handleChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setLocalValue(inputValue);

      const regex = new RegExp(regexp);

      if (!regexp || regex.test(inputValue)) {
        setError(false);
        onChange(inputValue, name);
      } else {
        setError(true);
        onChange("", name);
      }
    },
    [name, onChange, regexp],
  );

  useEffect(() => {
    if (!passSameValue && !preventRemove) {
      setLocalValue("");
    }
  }, [passSameValue, placeholder, regexp, name, preventRemove]);

  useEffect(() => {
    if (passSameValue) {
      if (passSameValue === localValue) {
        setError(false);
        onChange(localValue, name);
      } else {
        setError(true);
      }
    }
  }, [localValue, name, onChange, passSameValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 450);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  return (
    <div className="inputWrapper">
      <input
        ref={inputRef}
        type={type || "number"}
        {...(!type && { pattern: "[0-9]*", inputMode: "numeric" })}
        value={localValue}
        readOnly={readOnly}
        onChange={handleChange}
        placeholder={placeholder}
        className={clsx({ error, isSmall })}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Escape") {
            inputRef.current.blur();
          }
        }}
      />
      <p className="errorText">{error && errorMessage}</p>
    </div>
  );
};

export default memo(Input);
